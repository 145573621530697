// NEW SOLUTION
// Navbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CinemAILogo from '../logo/CinemAI_white.png';
import './Navbar.css'; 

const Navbar = ({ onExitVideoPlayer }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    // Check if onExitVideoPlayer is defined before calling it
    if (onExitVideoPlayer) {
      onExitVideoPlayer();
    }
    navigate('/');
  };

  return (
    <div className="navbar">
      <img
        src={CinemAILogo}
        alt="Logo"
        onClick={handleLogoClick}
        style={{
          width: '120px', 
          height: 'auto',
          cursor: 'pointer',
        }}
      />
    </div>
  );
};

export default Navbar;










// WORKING SOLUTION
// // Navbar.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import CinemAILogo from '../logo/CinemAI_white.png';
// import './Navbar.css'; 

// const Navbar = ({ onExitVideoPlayer }) => {
//   const navigate = useNavigate();

//   const handleLogoClick = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   return (
//     <div className="navbar">
//       <img src={CinemAILogo} alt="Logo" onClick={handleLogoClick} 
//       style={{
//         width: '120px', 
//         height: 'auto',
//         cursor: 'pointer',
//       }}/>
//     </div>
//   );
// };

// export default Navbar;





