// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import ThumbnailList from './components/ThumbnailList';
import VideoPlayer from './components/VideoPlayer';
import FilmSubmission from './components/FilmSubmission';
import HeroSection from './components/HeroSection';
import videoIds from './videoData.json'; 
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';


const App = () => {
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [videoDetails, setVideoDetails] = useState(null);

  const handleThumbnailClick = (videoId, videoDetails) => {
    setSelectedVideoId(videoId);
    setVideoDetails(videoDetails);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleExitVideoPlayer = () => {
    setSelectedVideoId(null);
    setVideoDetails(null); 
  };

  const VideoPlayerWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Navbar onExitVideoPlayer={handleExitVideoPlayer} />
        <VideoPlayer videoId={id} onExitVideoPlayer={handleExitVideoPlayer} videoDetails={videoDetails} />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <HeroSection /> 
              <div style={{ margin: '20px 0' }}> 
                <ThumbnailList videoIds={videoIds} onThumbnailClick={handleThumbnailClick} />
              </div>
              <FilmSubmission />
              <Footer />
            </>
          }
        />
        <Route path="/video/:id" element={<VideoPlayerWrapper />} />
      </Routes>
    </Router>
  );
};

export default App;

// return (
//   <Router>
//     <Routes>
//       <Route path="/" element={<><Navbar /><ThumbnailList videoIds={videoIds} onThumbnailClick={handleThumbnailClick} /><Footer /></>} />
//       <Route path="/video/:id" element={<VideoPlayerWrapper />} />
//     </Routes>
//   </Router>
// );
// };

// export default App;









// // App.js
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
// import ThumbnailList from './components/ThumbnailList';
// import VideoPlayer from './components/VideoPlayer';
// import videoIds from './videoData.json'; // Import video IDs from JSON file

// const App = () => {
//   const [selectedVideoId, setSelectedVideoId] = useState(null);
//   const [videoDetails, setVideoDetails] = useState(null);

//   const handleThumbnailClick = (videoId, videoDetails) => {
//     setSelectedVideoId(videoId);
//     setVideoDetails(videoDetails);
//   };

//   const handleExitVideoPlayer = () => {
//     setSelectedVideoId(null);
//     setVideoDetails(null);
//   };

//   const VideoPlayerWrapper = () => {
//     const { id } = useParams();

//     return (
//       <VideoPlayer
//         videoId={id}
//         onExitVideoPlayer={handleExitVideoPlayer}
//         videoDetails={videoDetails}
//       />
//     );
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={<ThumbnailList videoIds={videoIds} onThumbnailClick={handleThumbnailClick} />}
//         />
//         <Route path="/video/:id" element={<VideoPlayerWrapper />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;


























// // App.js
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
// import ThumbnailList from './components/ThumbnailList';
// import VideoPlayer from './components/VideoPlayer';
// import videoIds from './videoData.json'; // Import video IDs from JSON file

// const VideoPlayerWrapper = () => {
//   const { id } = useParams();
//   const [selectedVideoId, setSelectedVideoId] = useState(id);

//   const handleExitVideoPlayer = () => {
//     setSelectedVideoId(null);
//   };

//   return <VideoPlayer videoId={selectedVideoId} onExitVideoPlayer={handleExitVideoPlayer} />;
// };

// const App = () => {

//   const handleThumbnailClick = (videoId) => {
//     // You can add additional logic here if needed
//     console.log(`Thumbnail clicked: ${videoId}`);
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={<ThumbnailList videoIds={videoIds} onThumbnailClick={handleThumbnailClick} />}
//         />
//         <Route path="/video/:id" element={<VideoPlayerWrapper />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;



