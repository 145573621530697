// FilmSubmission.js
import React from 'react';
import './FilmSubmission.css';

const FilmSubmission = () => {
  return (
    <div className="film-submission-container">
      <video
        controls
        src="/videos/filmchallengevideo1.mp4"
        type="video/mp4"
      />
      <a
        href="https://forms.gle/AGxobPCyha6aqWEU7"
        target="_blank"
        rel="noopener noreferrer"
        className="join-button"
      >
        JOIN
      </a>
    </div>
  );
};

export default FilmSubmission;
