// Footer.js
import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
    <div className="footer">
      <p>CinemAI © 2024 | Powered by <a href="https://www.squada.io/" target="_blank" rel="noopener noreferrer">SQUADA</a></p>
    </div>
  );
};

export default Footer;
