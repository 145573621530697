// // // Close video
// // VideoPlayer.js
// import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';
// import './VideoPlayer.css';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();
//   const videoPlayerRef = useRef();

//   const [windowDimensions, setWindowDimensions] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   const handleResize = () => {
//     setWindowDimensions({
//       width: window.innerWidth,
//       height: window.innerHeight,
//     });
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const opts = {
//     height: windowDimensions.height.toString(),
//     width: windowDimensions.width.toString(),
//     playerVars: {
//       autoplay: 1,
//       origin: window.location.origin,
//     },
//   };

//   const handleExitFullScreen = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   useEffect(() => {
//     // Scroll into view when the videoId changes
//     if (videoPlayerRef.current) {
//       videoPlayerRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [videoId]);

//   return (
//     <div ref={videoPlayerRef} id="video-player-section">
//       {videoId ? (
//         <>
//           <div className="exit-button" onClick={handleExitFullScreen}>
//             Close Video
//           </div>
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             className="youtube-iframe"
//             containerClassName="youtube-container"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//           />
//           {videoDetails && (
//             <div className="video-details">
//               <h2>{videoDetails.title}</h2>
//               <p>{videoDetails.description}</p>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;










// NEW SOLUTION WORK IN PROGRESS 
// VideoPlayer.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import './VideoPlayer.css';

const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const opts = {
    height: windowDimensions.height.toString(),
    width: windowDimensions.width.toString(),
    playerVars: {
      autoplay: 1,
      origin: window.location.origin,
    },
  };

  useEffect(() => {
    document.getElementById('video-player-section').scrollIntoView({ behavior: 'smooth' });
  }, [videoId]);

  return (
    <div id="video-player-section">
      {videoId ? (
        <>
          <YouTube
            videoId={videoId}
            opts={opts}
            className="youtube-iframe"
            containerClassName="youtube-container"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
          {videoDetails && (
            <div className="video-details">
              <h2>{videoDetails.title}</h2>
              <p>{videoDetails.description}</p>
            </div>
          )}
        </>
      ) : (
        <p>Please select a video from the thumbnails to play.</p>
      )}
    </div>
  );
};

export default VideoPlayer;













// //WORKING SOLUTION FOR ThumbnailList.js (Displays Video, Title, Description, Channel)
// // VideoPlayer.js
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';
// import './VideoPlayer.css';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();
  

//   const [windowDimensions, setWindowDimensions] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   const handleResize = () => {
//     setWindowDimensions({
//       width: window.innerWidth,
//       height: window.innerHeight,
//     });
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

  

//   const opts = {
//     height: windowDimensions.height.toString(),
//     width: windowDimensions.width.toString(),
//     playerVars: {
//       autoplay: 1,
//       origin: window.location.origin, // Add this line
//     },
//   };
  


//   useEffect(() => {
//     // Scroll to the video player when a video is selected
//     document.getElementById('video-player-section').scrollIntoView({ behavior: 'smooth' });
//   }, [videoId]);

//   // const handleExit = () => {
//   //   onExitVideoPlayer();
//   //   navigate('/');
//   // };

//   return (
//     <div id="video-player-section"> {/* Add an ID to the container */}
//       {/* <button onClick={handleExit}>Exit Video Player</button> */}
//       {videoId ? (
//         <>
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             className="youtube-iframe"
//             containerClassName="youtube-container"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//           />
//           {videoDetails && (
//             <div className="video-details">
//               <h2>{videoDetails.title}</h2>
//               <p>{videoDetails.description}</p>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;




































// // VideoPlayer.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';
// import './VideoPlayer.css';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();
// //1366 x 784
//   const opts = {
//     height: '700',
//     width: '1300',
//     playerVars: {
//       autoplay: 1,
//     },
//   };

// //   const handleExit = () => {
// //     onExitVideoPlayer();
// //     navigate('/');
// //   };

//   return (
//     <div>
//       {/* <button onClick={handleExit}>Exit Video Player</button> */}
//       {videoId ? (
//         <>
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             className="youtube-iframe"
//             containerClassName="youtube-container"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//           />
//           {videoDetails && (
//             <div>
//               <h2>{videoDetails.title}</h2>
//               <p>{videoDetails.description}</p>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;








// // VideoPlayer.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();

//   const opts = {
//     height: '390',
//     width: '640',
//     playerVars: {
//       autoplay: 1,
//     },
//   };

//   const handleExit = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   return (
//     <div>
//       <button onClick={handleExit}>Exit Video Player</button>
//       {videoId ? (
//         <>
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             className="youtube-iframe"
//             containerClassName="youtube-container"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//           />
//           {videoDetails && (
//             <div>
//               {videoDetails.snippet && (
//                 <>
//                   <p>Channel Title: {videoDetails.snippet.channelTitle}</p>
//                   <h2>{videoDetails.snippet.title}</h2>
//                   <p>{videoDetails.snippet.description}</p>
//                 </>
//               )}
//               {videoDetails.contentDetails && (
//                 <>
//                   <p>Duration: {videoDetails.contentDetails.duration}</p>
//                   <p>Definition: {videoDetails.contentDetails.definition}</p>
//                 </>
//               )}
//               {videoDetails.statistics && (
//                 <p>View Count: {videoDetails.statistics.viewCount}</p>
//               )}
//               {/* Add more details as needed */}
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;







// // VideoPlayer.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();

//   const opts = {
//     height: '390',
//     width: '640',
//     playerVars: {
//       autoplay: 1,
//     },
//   };

//   const handleExit = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   return (
//     <div>
//       <button onClick={handleExit}>Exit Video Player</button>
//       {videoId ? (
//         <>
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             className="youtube-iframe"
//             containerClassName="youtube-container"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//           />
//           {videoDetails && (
//             <div>
//               <h2>{videoDetails.title}</h2>
//               <p>{videoDetails.description}</p>
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;

































// // VideoPlayer.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';

// const VideoPlayer = ({ videoId, onExitVideoPlayer, videoDetails }) => {
//   const navigate = useNavigate();

//   const opts = {
//     height: '390',
//     width: '640',
//     playerVars: {
//       autoplay: 1,
//     },
//   };

//   const handleExit = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   console.log('Video Details:', videoDetails);

//   return (
//     <div>
//       <button onClick={handleExit}>Exit Video Player</button>
//       {videoId ? (
//         <>
//           <YouTube videoId={videoId} opts={opts} />
//           {videoDetails && (
//             <div>
//               <h2>{videoDetails.title}</h2>
//               <p>{videoDetails.description}</p>
//               {/* Add more details as needed */}
//             </div>
//           )}
//         </>
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;





// // VideoPlayer.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import YouTube from 'react-youtube';

// const VideoPlayer = ({ videoId, onExitVideoPlayer }) => {
//   const navigate = useNavigate();

//   const opts = {
//     height: '390',
//     width: '640',
//     playerVars: {
//       autoplay: 1,
//     },
//   };

//   const handleExit = () => {
//     onExitVideoPlayer();
//     navigate('/');
//   };

//   return (
//     <div>
//       <button onClick={handleExit}>Exit Video Player</button>
//       {videoId ? (
//         <YouTube videoId={videoId} opts={opts} />
//       ) : (
//         <p>Please select a video from the thumbnails to play.</p>
//       )}
//     </div>
//   );
// };

// export default VideoPlayer;



