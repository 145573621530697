  // ThumbnailList.js
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import { Link } from 'react-router-dom';
  import './ThumbnailList.css'; 
  
  const ThumbnailList = ({ videoIds, onThumbnailClick }) => {
    const [thumbnails, setThumbnails] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(
                ','
              )}&key=${process.env.REACT_APP_API_KEY}&part=snippet`
            );
            setThumbnails(response.data.items);
            console.log('API Response:', response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [videoIds]);
  
    return (
      <div className="thumbnail-list">
        {thumbnails.map((thumbnail) => (
          <Link key={thumbnail.id} to={`/video/${thumbnail.id}`}>
            <div
              onClick={() => {
                console.log('Thumbnail Clicked:', thumbnail.snippet);
                onThumbnailClick(thumbnail.id, thumbnail.snippet);
              }}
              className="thumbnail"
            >
              <img src={thumbnail.snippet.thumbnails.default.url} alt={thumbnail.snippet.title} />
              <p>{thumbnail.snippet.title}</p>
            </div>
          </Link>
        ))}
      </div>
    );
  };
  
  export default ThumbnailList;


  












//   // ThumbnailList.js
//   import React, { useState, useEffect } from 'react';
//   import axios from 'axios';
//   import { Link } from 'react-router-dom';
//   import './ThumbnailList.css'; 
  
//   const ThumbnailList = ({ videoIds, onThumbnailClick }) => {
//     const [thumbnails, setThumbnails] = useState([]);
  
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const response = await axios.get(
//             `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(
//               ','
//             )}&key=AIzaSyCgbFYdC0NVHU_rgTLDbLez1MCFJr0TQas&part=snippet`
//           // `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(',')}&key=AIzaSyCgbFYdC0NVHU_rgTLDbLez1MCFJr0TQas&part=snippet,contentDetails,statistics`
//           );
//           setThumbnails(response.data.items);
//           console.log('API Response:', response.data);
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       };
  
//       fetchData();
//     }, [videoIds]);
  
//     return (
//       <div className="thumbnail-list">
//         {thumbnails.map((thumbnail) => (
//           <Link key={thumbnail.id} to={`/video/${thumbnail.id}`}>
//             <div
//               onClick={() => {
//                 console.log('Thumbnail Clicked:', thumbnail.snippet);
//                 onThumbnailClick(thumbnail.id, thumbnail.snippet);
//               }}
//               className="thumbnail"
//             >
//               <img src={thumbnail.snippet.thumbnails.default.url} alt={thumbnail.snippet.title} />
//               <p>{thumbnail.snippet.title}</p>
//             </div>
//           </Link>
//         ))}
//       </div>
//     );
//   };
  
//   export default ThumbnailList;

























// // ThumbnailList.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// const ThumbnailList = ({ videoIds, onThumbnailClick }) => {
//     const [thumbnails, setThumbnails] = useState([]);
  
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const response = await axios.get(
//             `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(',')}&key=AIzaSyCgbFYdC0NVHU_rgTLDbLez1MCFJr0TQas&part=snippet`
//           );
//           setThumbnails(response.data.items);
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       };
  
//       fetchData();
//     }, [videoIds]);
  
//     return (
//         <div>
//           {thumbnails.map((thumbnail) => (
//             <Link key={thumbnail.id} to={`/video/${thumbnail.id}`}>
//               <div onClick={() => {
//                 console.log('Thumbnail Clicked:', thumbnail.snippet);
//                 onThumbnailClick(thumbnail.id, thumbnail.snippet);
//               }}>
//                 <img src={thumbnail.snippet.thumbnails.default.url} alt={thumbnail.snippet.title} />
//                 <p>{thumbnail.snippet.title}</p>
//               </div>
//             </Link>
//           ))}
//         </div>
//       );
//     };
    
//     export default ThumbnailList;




// // ThumbnailList.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// const ThumbnailList = ({ videoIds, onThumbnailClick }) => {
//   const [thumbnails, setThumbnails] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(',')}&key=AIzaSyCgbFYdC0NVHU_rgTLDbLez1MCFJr0TQas&part=snippet`
//         );
//         setThumbnails(response.data.items);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [videoIds]);

//   return (
//     <div>
//       {thumbnails.map((thumbnail) => (
//         <Link key={thumbnail.id} to={`/video/${thumbnail.id}`}>
//           <div onClick={() => onThumbnailClick(thumbnail.id, thumbnail.snippet)}>
//             <img src={thumbnail.snippet.thumbnails.default.url} alt={thumbnail.snippet.title} />
//             <p>{thumbnail.snippet.title}</p>
//           </div>
//         </Link>
//       ))}
//     </div>
//   );
// };

// export default ThumbnailList;



