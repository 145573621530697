// // HeroSection.js (With banner click user lowers a player) //Close video
// NEW SOLUTION WORK IN PROGRESS 
// HeroSection.js

import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HeroSection.css';
import axios from 'axios';
import videoIds from '../videoData.json';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';

const HeroSection = () => {
  const [randomMovies, setRandomMovies] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(',')}&key=${process.env.REACT_APP_YOUTUBE_API_KEY_BANNER}&part=snippet`
        );
        setRandomMovies(response.data.items);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, [videoIds]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    afterChange: (current) => handleAfterChange(current),
  };

  const handleAfterChange = (current) => {
    if (sliderRef.current) {
      clearTimeout(sliderRef.current.timeoutId);

      sliderRef.current.timeoutId = setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickNext();
        }
      }, 10000);
    }
  };

  useEffect(() => {
    return () => {
      // Clear the timeout when the component is unmounted
      if (sliderRef.current) {
        clearTimeout(sliderRef.current.timeoutId);
      }
    };
  }, []);

  const handleThumbnailClick = (videoId, videoDetails) => {
    setSelectedVideo({ videoId, videoDetails });
  };

  const handleExitVideoPlayer = () => {
    setSelectedVideo(null);
    navigate('/');
  };

  return (
    <div className="hero-section">
      <Slider ref={sliderRef} {...sliderSettings}>
        {randomMovies.map((movie) => (
          <div key={movie.id} className="movie-slide">
            <img
              src={movie.snippet.thumbnails.high.url}
              alt={movie.snippet.title}
              onClick={() => handleThumbnailClick(movie.id, movie.snippet)}
            />
            <div className="movie-info">
              <h2>{movie.snippet.title}</h2>
              {/* <p>{movie.snippet.description}</p> */}
              <p>{truncateText(movie.snippet.description, 150)}</p>
            </div>
          </div>
        ))}
      </Slider>

      {selectedVideo && (
        <VideoPlayer
          videoId={selectedVideo.videoId}
          onExitVideoPlayer={handleExitVideoPlayer}
          videoDetails={selectedVideo.videoDetails}
        />
      )}
    </div>
  );
};

export default HeroSection;










// //WORKING SOLUTION
// // HeroSection.js

// import React, { useState, useEffect, useRef } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './HeroSection.css';
// import axios from 'axios';
// import videoIds from '../videoData.json';
// import { Link, useNavigate, useLocation } from 'react-router-dom';


// const HeroSection = () => {
//   const [randomMovies, setRandomMovies] = useState([]);
//   const navigate = useNavigate();
//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(
//           `https://www.googleapis.com/youtube/v3/videos?id=${videoIds.join(
//             ','
//           )}&key=${process.env.REACT_APP_YOUTUBE_API_KEY_BANNER}&part=snippet`
//         );
//         setRandomMovies(response.data.items);
//       } catch (error) {
//         console.error('Error fetching videos:', error);
//       }
//     };

//     fetchVideos();
//   }, [videoIds]);

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 10000,
//     afterChange: (current) => handleAfterChange(current),
//   };

//   const truncateText = (text, maxLength) => {
//     return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
//   };



// //   const handleBannerClick = (videoId, videoDetails) => {
// //       navigate(`/video/${videoId}`, { state: { videoDetails } });
// //   };

// const handleBannerClick = (videoId, videoDetails) => {
//     // Check if videoDetails is available
//     const snippet = videoDetails && videoDetails.snippet;
//     const { title, description } = snippet || {};
//     navigate(`/video/${videoId}`, { state: { videoDetails: { title, description } } });
//   };
  
    

// const handleAfterChange = (current) => {
//     if (sliderRef.current) {
//       clearTimeout(sliderRef.current.timeoutId);
  
//       sliderRef.current.timeoutId = setTimeout(() => {
//         if (sliderRef.current) {
//           sliderRef.current.slickNext();
//         }
//       }, 10000);
//     }
//   };
  

//   useEffect(() => {
//     return () => {
//       // Clear the timeout when the component is unmounted
//       if (sliderRef.current) {
//         clearTimeout(sliderRef.current.timeoutId);
//       }
//     };
//   }, []);

//   return (
//     <div className="hero-section">
//       <Slider ref={sliderRef} {...sliderSettings}>
//         {randomMovies.map((movie) => (
//           <div
//             key={movie.id}
//             className="movie-slide"
//             onClick={() => handleBannerClick(movie.id, movie.snippet)}
//           >
//             <img src={movie.snippet.thumbnails.high.url} alt={movie.snippet.title} />
//             <div className="movie-info">
//               <h2>{movie.snippet.title}</h2>
//               <p>{truncateText(movie.snippet.description, 150)}</p>
//             </div>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default HeroSection;














































// // HeroSection.js (Without banner click to access the player)
// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './HeroSection.css';
// import axios from 'axios';
// import videoBannerIds from '../videoBannerData.json'; // Import the video IDs from the JSON file

// const HeroSection = () => {
//   const [randomMovies, setRandomMovies] = useState([]);

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(
//           `https://www.googleapis.com/youtube/v3/videos?id=${videoBannerIds.join(',')}&key=AIzaSyCQWBdpdXA5A-vwwPzu1BpAh7x5x-BhdgU&part=snippet`
//         );
//         setRandomMovies(response.data.items);
//       } catch (error) {
//         console.error('Error fetching videos:', error);
//       }
//     };

//     fetchVideos();
//   }, [videoBannerIds]);

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   const truncateText = (text, maxLength) => {
//     return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
//   };

//   return (
//     <div className="hero-section">
//       <Slider {...sliderSettings}>
//         {randomMovies.map((movie) => (
//           <div key={movie.id} className="movie-slide">
//             <img src={movie.snippet.thumbnails.high.url} alt={movie.snippet.title} />
//             <div className="movie-info">
//               <h2>{movie.snippet.title}</h2>
//               <p>{truncateText(movie.snippet.description, 150)}</p>
//             </div>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default HeroSection;











// // AIzaSyCQWBdpdXA5A-vwwPzu1BpAh7x5x-BhdgU

// // HeroSection.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './HeroSection.css';

// const HeroSection = () => {
//   const [randomMovies, setRandomMovies] = useState([]);

//   useEffect(() => {
//     const fetchRandomMovies = async () => {
//       try {
//         const response = await axios.get(
//           `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyCQWBdpdXA5A-vwwPzu1BpAh7x5x-BhdgU&part=snippet&chart=mostPopular`
//         );
//         setRandomMovies(response.data.items);
//       } catch (error) {
//         console.error('Error fetching random movies:', error);
//       }
//     };

//     fetchRandomMovies();
//   }, []); 

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   // Function to limit text to 150 characters
//   const truncateText = (text, maxLength) => {
//     return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
//   };

//   return (
//     <div className="hero-section">
//       <Slider {...sliderSettings}>
//         {randomMovies.map((movie) => (
//           <div key={movie.id} className="movie-slide">
//             <img src={movie.snippet.thumbnails.high.url} alt={movie.snippet.title} />
//             <div className="movie-info">
//               <h2>{movie.snippet.title}</h2>
//               <p>{truncateText(movie.snippet.description, 150)}</p>
//             </div>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default HeroSection;























// // HeroSection.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './HeroSection.css';

// const HeroSection = () => {
//   const [randomMovies, setRandomMovies] = useState([]);

//   useEffect(() => {
//     const fetchRandomMovies = async () => {
//       try {
//         // Assuming you have an API endpoint to get random movies
//         const response = await axios.get(process.env.REACT_APP_API_KEY);
//         setRandomMovies(response.data.items);
//       } catch (error) {
//         console.error('Error fetching random movies:', error);
//       }
//     };

//     fetchRandomMovies();
//   }, []); // Empty dependency array means this effect runs once on component mount

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   return (
//     <div className="hero-section">
//       <Slider {...settings}>
//         {randomMovies.map((movie) => (
//           <div key={movie.id} className="slick-item">
//             <img src={movie.snippet.thumbnails.default.url} alt={movie.snippet.title} />
//             <div className="movie-info">
//               <h2>{movie.snippet.title}</h2>
//               <p>{movie.snippet.description}</p>
//             </div>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default HeroSection;

